<script>
import appConfig from "@/app.config";
import Utils     from "@/js/utils";
import Layout    from "@/router/layouts/main";

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      if (this.$route.name == 'feedback_form') return 'Feedback Form'
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  data() {
    return {
      loading: true,
      routerReady: false,
      permittedPages: [],
      contexts: ["destination", "feedback", "reputation"],
      user: {},
      alwaysPermitted: ["white-label", "user-settings", "email-notifications", "queries", "signup", "documentation", "reports"],
      routes: {},
      pageOptions: {}
    }
  },

  created() {
    this.$router.isReady().then(async () => {
      this.routes = require("./router/routes").default;

      this.routerReady = true;
      if (this.$route.name != "feedback_form") {
        this.user = await this.$store.dispatch("user/fetch");
        setTimeout(() => this.pushDefaultURLParams(), 1000);
      } else this.loading = false;
    });
  },

  mounted() {
    window.addEventListener("updateOpts", ({ detail }) => {
      this.$nextTick(() => this.updatePageOpts(detail));
    });
    setTimeout(() => this.notifyExtension("OLY_EXTENSION_AUTH_LOGIN"), 1000);
  },

  beforeUnmount() {
    window.removeEventListener("updateOpts");
  },

  components: { Layout },

  methods: {
    ...Utils.filter,
    ...Utils.permissions,
    ...Utils.auth,
    async pushDefaultURLParams() {
      let query = this.readParamsFromContract();
      query = this.checkParams(query);
      await this.$router.push({ path: this.$route.path, query, hash: this.$route.hash });
      this.loading = false;
    },
    setPermittedRoutes() {
      this.permittedPages = this.permissions.map(p => `${p.component}_${p.permission_type}`);
    },
    permittedRoutes(routes, contract, context) {
      if (!contract) return [];
      let result = [];
      for (let i = 0; i < routes.length; i++) {
        if (!this.can(routes[i], context || routes[i]?.name, this.contract, this.user, true)) continue;
        if (!context && !/settings|documentation|reports/.test(routes[i]?.name) && !contract.products.includes(routes[i]?.name)) continue;

        if (routes[i].children?.length) result.push({ ...routes[i], children: this.permittedRoutes(routes[i].children, contract, (context || routes[i]?.name)) });
        else result.push(routes[i]);
      }
      return result;
    },
    updatePageOpts(opts) {
      const o = opts || { no_padding: false, no_footer: false, ignore_container_flex: false, statusMessage: { status: "", icon: "" } };
      Object.entries(o).forEach(([k, v]) => this.pageOptions[k] = v);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    isDashboard() {
      return !/feedback_form|documentation|reports_show_template|replab|signup/.test(this.$route.name);
    },
    permissions () {
      return this.user.permissions_by_contract?.[this.contract.id] || [];
    },
    context() {
      return this.$route.path.split("/")[1];
    },
    hasPagePermission() {
      return this.$route.path == "/" || this.can(this.$route, this.context, this.contract, this.user);
    },
    hasWLContract() {
      for (let i = 0; i < this.contexts.length; i++) {
        if (this.user[`${this.contexts[i]}_contracts`].find(c => c.whitelabel)) return true;
      }
      return false;
    },
    computedRoutes() {
      return this.permittedRoutes(this.routes, this.contract);
    },
    options() {
      return this.computedRoutes.filter(r => r.name != "settings");
    },
    layoutToRender() {
      return this.isDashboard ? "Layout" : "div";
    }
  },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) {
          this.pushDefaultURLParams();
          this.setPermittedRoutes();
        }
      },
      immediate: true
    },
    options: {
      handler: function () { // if there is only one available context/product, redirect to it
        if (this.$route.path == "/" && this.options.length == 1 && this.options[0].meta?.redirect)
          this.$router.push({ path: this.options[0].meta.redirect, query: { ...this.$route.query } });
      },
      immediate: true
    },
    "$route.name": {
      handler: function () {
        this.updatePageOpts();
      },
      immediate: true
    }
  }
};
</script>

<template>
  <div v-if="routerReady">
    <template v-if="!loading">
      <template v-if="!/feedback_form|signup/.test($route.name)">
        <component :is="layoutToRender" :routes="computedRoutes" v-bind="pageOptions">
          <router-view v-if="hasPagePermission" :routes="computedRoutes" :opts="pageOptions" />
          <div v-else class="card px-5 align-self-center mx-auto">
            <div class="card-body text-center">
              <strong class="font-size-18">{{ $t("general.no_permission_to_view_page") }}</strong>
            </div>
          </div>
        </component>
      </template>

      <router-view v-else />
    </template>

    <div v-else class="loading-user-data fw-bold">
      <div class="spinner-border text-success me-3"></div>
      {{ $t("general.loading_user_data") }}
    </div>
  </div>
</template>
