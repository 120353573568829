if (!window.oly) window.oly = {};

import dayjs        from "dayjs";
import filterParams from "@/data/filter-params-map";
import composition  from "@/data/composition";
import countries    from "@/data/countries";
import locales      from "@/data/locales";


window.oly.utils = {
  authParams: ["code", "ep", "token", "auth_token", "override_user"],
  products:   ["destination", "feedback", "reputation", "documentation"],
  scores:     ["overall", "food_beverages", "facilities", "service", "cleanliness", "location", "value", "fnb", "ambience", "sustainable_travel", "covid", "health_precautions", "sanitary_safety"],
  scoresHash: { travel_composition: composition, reviewer_country: countries, language: locales },
  locales: ["en", "ca", "nl", "it", "fi", "fr", "de", "no", "pt", "ru", "es", "sv", "tr"],
  permittedReviewSources: ["google", "facebook_pages"],
  date: {
    allPeriods() {
      return {
        day:     this.$t("reports.periods.day"),
        week:    this.$t("reports.periods.week"),
        month:   this.$t("reports.periods.month"),
        bimonth: this.$t("reports.periods.bimonth"),
        quarter: this.$t("reports.periods.quarter"),
        year:    this.$t("reports.periods.year")
      }
    },
  },
  filter: {
    readParamsFromContract() {
      const query  = { ...this.$route.query };
      const params = this.contract?.dashboard_default_params || {};
      Object.entries(params).forEach(([param, value]) => {
        if (!query[param]) query[param] = value;
      });
      return query;
    },
    readParams(query) {
      let res = window.oly.utils.deepClone(query);
      Object.keys(res).forEach(p => {
        if (/^custom\.\d+$/.test(p) && res[p] && res[p]?.length) {
          if (Array.isArray(res[p])) res[`data.customerFilter.${p.split(".")[1]}`] = res[p].filter(n => n);
          else if (!/null|undefined|""/.test(res[p])) res[`data.customerFilter.${p.split(".")[1]}`] = res[p];
          delete res[p];
        } else if (filterParams[p]) {
          res[filterParams[p]] = res[p];
          if (filterParams[p] != p) delete res[p];
        }
      });
      return res;
    },
    convertParamsToURLFormat(query) {
      return Object.keys(query).reduce((res, k) => {
        const p = this.param(k);
        if (p) res[p] = query[k];
        return res;
      }, {});
    },
    checkParams(query) {
      if (this.$route.meta.escapeParamsVerification) return query;
      const baseQueryParams = ["sid", ...window.oly.utils.authParams];
      const permittedParams = this.$route.meta.params || [];
      let res = {};
      Object.keys(query).forEach(p => {
        if (!baseQueryParams.includes(p) && permittedParams) {
          let param = this.param(p) || p;
          if (/^data.customerFilter\.\d+$/.test(p)) res[`custom.${p.split(".")[2]}`] = query[p];
          if (permittedParams.includes(param)) res[param] = query[p];
        } else res[p] = query[p];
      });
      return res;
    },
    param(p) {
      return Object.keys(filterParams).find(f => filterParams[f] == p);
    }
  },
  auth: {
    async notifyExtension(type) {
      window.postMessage({
        type,
        auth_token: this.$route.query.token,
        ep_token:   window.ep_token,
        ep_code:    window.ep_code,
        createdAt:  new Date().toString(),
        user:       await this.$store.dispatch("user/fetch"),
      }, "*");
    },
  },
  defaultDateRange () {
    var d = new Date();
    d.setMonth(d.getMonth() - 6);
    return [d, new Date()]
  },
  getLastYears(n) {
    const currentYear = dayjs().year();
    return Array.from({ length: n }, (_, i) => currentYear - i);
  },
  getMonths(y) {
    const year          = y || dayjs().year();
    const isCurrentYear = dayjs().year() == year;
    let months          = Array.from(Array(12).keys()).map(m => String(m));
    let currentMonth    = dayjs().month();
    if (isCurrentYear) months = months.slice(0, currentMonth);
    return months;
  },
  dateShortcuts() {
    const today = new Date();
    return [
      {
        text: this.$t("filter.date_ranges.today"),
        onClick() { return [today, today] }
      },
      {
        text: this.$t("filter.date_ranges.yesterday"),
        onClick() {
          let yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          return [yesterday, yesterday];
        }
      },
      {
        text: this.$t("filter.date_ranges.last_7_days"),
        onClick() {
          let lastWeek = new Date();
          lastWeek.setDate(lastWeek.getDate() - 6);
          return [lastWeek, today];
        }
      },
      {
        text: this.$t("filter.date_ranges.this_month"),
        onClick() {
          let thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
          return [thisMonth, today];
        }
      },
      {
        text: this.$t("filter.date_ranges.last_month"),
        onClick() {
          let lastMonthFirstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          let lastMonthLastDay  = new Date(today.getFullYear(), today.getMonth(), 0);
          return [lastMonthFirstDay, lastMonthLastDay];
        }
      },
      {
        text: this.$t("filter.date_ranges.last_6_months"),
        onClick() {
          let last6Months = new Date();
          last6Months.setMonth(today.getMonth() - 6);
          return [last6Months, today];
        }
      },
      {
        text: this.$t("filter.date_ranges.ytd"),
        onClick() {
          let firstDayOfYear = new Date(today.getFullYear(), 0, 1);
          return [firstDayOfYear, today];
        }
      },
      {
        text: this.$t("filter.date_ranges.last_year"),
        onClick() {
          const lastYear         = dayjs().subtract(1, "year");
          const lastYearFirstDay = lastYear.startOf("year").toDate()
          const lastYearLastDay  = lastYear.endOf("year").toDate()
          return [lastYearFirstDay, lastYearLastDay];
        }
      },
    ]
  },
  round (v, f) {
    if (v == 10 || v == 0) return String(v);
    if (!v && v != 0) return '-';
    f = f == undefined ? 1 : f
    return parseFloat(v).toFixed(f)
  },
  formatNumber(v, f = 1) {
    if ((!v && v != 0) || v == "-") return "-";
    const isInteger       = Number.isInteger(Number(v));
    const storageLocale   = this.loadState("locale");
    const navigatorLocale = navigator.language.split("-").flatMap(p => p.split("_"));
    let locale;
    if (navigatorLocale[1] && storageLocale == navigatorLocale[0]) locale = `${storageLocale}-${navigatorLocale[1]}`;
    else locale = storageLocale;
    return Intl.NumberFormat(locale, { minimumFractionDigits: isInteger ? 0 : f }).format(v);
  },
  setLoading(enable) {
    if (enable) {
      this.show    = false
      this.loading = true
      document.body.style.cursor='wait';
    } else {
      this.show    = true
      this.loading = false
      document.body.style.cursor='';
    }
  },

  standardizeScoreName(name, type) {
    const values = this.scoresHash[type];
    if (values?.[name]) return values[name];
    return name;
  },

  capitalizeFirstLetter(str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  },

  updatePageOpts(opts) {
    window.dispatchEvent(new CustomEvent("updateOpts", { detail: opts }));
  },

  // too slow, use [...new Set(array)]
  uniq(v, i, self) {
    return self.indexOf(v) === i;
  },
  sort(a,b) {
    if (!a.localeCompare) return a - b
    return a.localeCompare(b, undefined, {sensitivity: 'base'})
  },
  sortField(array, field) {
    return array.sort((a,b) => this.sort(a[field], b[field]))
  },
  uniqSorted(array, field) {
    array = [...new Set(array)]
    array = field ? this.sortField(array, field) : array.sort(this.sort)
    return array
  },
  uniqueFromSorted(array, col) {
    let result = []
    let j = -1
    for (let i = 0; i < array.length; i++) {
      if (!result[j] || (result[j][col] != array[i][col])) {
        result[++j] = array[i]
      }
    }
    return result
  },

  loadState(key) {
    try {
      return JSON.parse(window.localStorage.getItem(key)) || []
    } catch {
      return []
    }
  },

  saveState(key, state) {
    if (!state || state.length == 0) return;
    window.localStorage.setItem(key, JSON.stringify(state))
  },

  calcTES (rating, n_reviews_count, sentiment, s_reviews_count) {
    rating          = parseFloat(rating)
    sentiment       = parseFloat(sentiment)
    n_reviews_count = parseInt(n_reviews_count)
    s_reviews_count = parseInt(s_reviews_count)
    let tes = Number(this.round(((rating * n_reviews_count) + (sentiment * s_reviews_count))/(n_reviews_count+s_reviews_count)))
    if (isNaN(tes)) tes = null
    return tes
  },
  slice (obj, keys) {
    return Object.keys(obj).filter( key => keys.includes(key) ).reduce( (res, key) => (res[key] = obj[key], res), {} )
  },

  validURL(str) {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    return !!regex.test(str);
  },

  groupByUniq(obj, key) {
    return obj.reduce((h, i) => {
      h[i[key]] = i
      return h
    }, {})
  },

  groupBy(obj, key) {
    return obj.reduce((h, i) => {
      h[i[key]] ??= []
      h[i[key]].push(i)
      return h
    }, {})
  },

  validEmail(str) {
    return !!/^[+\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str);
  },

  slugify(str) {
      return  str.toString().toLowerCase()
      .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')       // Special Characters #1
      .replace(/[èÈéÉêÊëË]+/g, 'e')       	// Special Characters #2
      .replace(/[ìÌíÍîÎïÏ]+/g, 'i')       	// Special Characters #3
      .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')       	// Special Characters #4
      .replace(/[ùÙúÚûÛüÜ]+/g, 'u')       	// Special Characters #5
      .replace(/[ýÝÿŸ]+/g, 'y')       		// Special Characters #6
      .replace(/[ñÑ]+/g, 'n')       			// Special Characters #7
      .replace(/[çÇ]+/g, 'c')       			// Special Characters #8
      .replace(/[ß]+/g, 'ss')       			// Special Characters #9
      .replace(/[Ææ]+/g, 'ae')       			// Special Characters #10
      .replace(/[Øøœ]+/g, 'oe')       		// Special Characters #11
      .replace(/[%]+/g, 'pct')       			// Special Characters #12
      .replace(/\./g, '_')                // Add: replace period with _
      .replace(/\s+/g, '-')           		// Replace spaces with -
        .replace(/[^\w-]+/g, '')       		// Remove all non-word chars
        .replace(/--+/g, '-')         		// Replace multiple - with single -
        .replace(/^-+/, '')             		// Trim - from start of text
        .replace(/-+$/, '');            		// Trim - from end of text
  },

  isNullOrUndefined(value) {
    return !value && value != 0;
  },

  compareSources(requestSource, responseSource) {
    if (!responseSource) return
    return this.slugify(responseSource.replace(/\s/g, "")||"").toLowerCase().replace(/_/g, "").includes(requestSource.replace(/_/g, ""));
  },

  deepClone(object) {
    let result, value, key;
    if (typeof object !== "object" || object === null) return object;

    result = Array.isArray(object) ? [] : {};

    for (key in object) {
      value = object[key];
      result[key] = this.deepClone(value);
    }

    return result;
  },

  compareObjects(objA, objB) {
    if (!objA || !objB) return false;
    if (Object.keys(objA).length !== Object.keys(objB).length) return false;

    for (const key in objA) {
      const val1 = objA[key], val2 = objB[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if ((areObjects && !this.compareObjects(val1, val2)) || (!areObjects && val1 !== val2)) return false;
    }
    return true;
  },

  isObject(obj) {
    return obj != null && typeof obj === "object";
  },

  async asyncForEach(array = [], callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  },

  flattenGroupIds(group_ids) {
    return (group_ids || []).map(g => JSON.parse(g)).flat();
  },

  // for the google translate API to not translate variables
  addEscapeTranslation(string = "", prefix = "#", suffix = "#") {
    let content    = `${string}`;
    let match      = content.match(prefix);
    let newContent = "";

    while (match?.length > 0) {
      newContent += content.substring(0, match.index) + "<span translate='no' ";
      content     = content.substring(match.index + prefix.length);
      match       = content.match(suffix);

      newContent += `data-original-variable='${prefix}${content.substring(0, match.index)}${suffix}'>${content.substring(0, match.index)}</span>`;
      content     = content.substring(match.index + suffix.length);
      match       = content.match(prefix);
    }
    newContent += content;
    return newContent;
  },

  removeEscapeTranslation(string = "") {
    let content    = `${string}`;
    let regex      = /<span translate='no' data-original-variable='/; //45
    let match      = content.match(regex) || [];
    let newContent = "";

    while (match?.length > 0) {
      newContent  += content.substring(0, match.index);
      content      = content.substring(match.index + 45);
      match        = content.match(/'>/);
      let variable = content.substring(0, match.index);

      match        = content.match("</span>") //7
      content      = content.substring(match.index + 7);
      newContent  += variable;
      match        = content.match(regex);
    }
    newContent += content;
    return newContent;
  },

  replaceVariablesWithValue(text = "", variables = {}, prefix = "#", suffix = "#") {
    if (text.length <= 0) return;
    let content    = text;
    let newContent = "";
    let match      = content.match(prefix);

    while (match?.length > 0) {
      newContent += content.substring(0, match.index);
      content     = content.substring(match.index + prefix.length);
      match       = content.match(prefix)

      let data    = variables[content.substring(0, match.index)];
      newContent += data || "";
      content     = content.substring(match.index + suffix.length);
      match       = content.match(prefix);
    }
    newContent += content;
    return newContent;
  },

  stylesHash: {
    primary_color:            ["--bs-primary"],
    secondary_color:          ["--bs-secondary"],
    sidebar_background_color: ["--sidebar-dark-bg"],
    sidebar_text_color:       ["--sidebar-text"],
    topbar_background_color:  ["--header-bg"],
    topbar_text_color:        ["--header-text"],
    logo_url:                 ["--logo-sm", "--logo-light", "--logo-dark"],
    logo_width:               ["--logo-width"],
    logo_margin_top:          ["--logo-margin-top"]
  },

  setDashboardStyles(contract) {
    let root = document.querySelector(":root");
    if (!root) return;
    Object.keys(this.stylesHash).forEach(field => {
      this.stylesHash[field].forEach(f => {
        let value = contract.whitelabel_styles?.[field];
        if (field == "logo_url") {
          if (!contract.whitelabel) value = `var(${f}-default)`;
          else if (value) value = `url(${value})`;
        }
        if (field == "logo_width"  && value) value += "%";
        if (field == "logo_margin_top" && value) value += "px";
        else if (!value) value = `var(${f}-default)`;
        root.style.setProperty(f, value);
      });
    });
  },

  travelCompositionIconClasses: {
    families:     'mdi mdi-human-male-female-child',
    single:       'mdi mdi-account',
    solo:         'mdi mdi-account',
    couples:      'mdi mdi-human-male-female',
    friends:      'mdi mdi-account-group',
    group:        'mdi mdi-account-group',
    business:     'mdi mdi-account-tie',
    seniors:      'mdi mdi-human-walker',
    young_adults: 'mdi mdi-account-group',
    other:        'mdi mdi-dots-horizontal',
  },

  sentimentIconClasses: {
    overall:        "bi bi-star-fill",
    facilities:     "mdi mdi-home-city",
    service:        "mdi mdi-face-agent",
    cleanliness:    "mdi mdi-shimmer",
    location:       "mdi mdi-map-marker",
    value:          "mdi mdi-currency-eur",
    food_beverages: "mdi mdi-food-fork-drink",
    fnb:            "mdi mdi-food-fork-drink",
    ambience:       "mdi mdi-candle",
    sustainable_travel: "mdi mdi-earth",
    sanitary_safety:    "mdi mdi-lotion-plus",
    covid_mentions:     "mdi mdi-virus",
    health_precautions: "mdi mdi-medical-cotton-swab"
  },

  sources: {
    iconUrl(name) {
      return `https://sdk.olery.com/assets/images/logos/${name}.png`
    },
  },

  percent: {
    format(value, {decimals = 1, emptyValue = '-', symbol = '%'} = {}) {
      if (!value && value != 0) return emptyValue

      value = value * 100
      const frac = Math.abs(value) >= 100 ? 0 : decimals
      value = window.oly.utils.round(value, frac)

      return `${value} ${symbol}`
    },

    delta(valueMain, valueBenchmark) {
      if (!valueBenchmark) return `-`
      return window.reports.percentFormat((valueMain - valueBenchmark) / valueBenchmark)
    },
  },

  ratings: {
    colorHash: {
      "rating0-4": "#d54939",
      "rating5-8": "#e64d3c",
      "rating9-12": "#ea5c39",
      "rating13-16": "#ee6a36",
      "rating17-20": "#f37c33",
      "rating21-24": "#f48e2f",
      "rating25-28": "#f4992d",
      "rating29-32": "#f4a42c",
      "rating33-36": "#f4ae2c",
      "rating37-40": "#f4b82c",
      "rating41-44": "#f4c02c",
      "rating45-48": "#f4c72c",
      "rating49-52": "#f4cd2c",
      "rating53-56": "#f0ce2e",
      "rating57-60": "#e8ce31",
      "rating61-64": "#ddce36",
      "rating65-68": "#d2ce3b",
      "rating69-72": "#c5ce40",
      "rating73-76": "#b8ce46",
      "rating77-80": "#a7ce4e",
      "rating81-84": "#96ce56",
      "rating85-88": "#83ce5f",
      "rating89-92": "#75ce65",
      "rating93-96": "#63cc6d",
      "rating97-100": "#54ca74",
      "rating-unknown": "#c7c7c7"
    },

    mod4(value) {
      return Math.floor((value - 1) / 4) * 4
    },

    toCss(value10) {
      return this.toCss100(parseFloat(value10) * 10)
    },

    toCss100(value) {
      if (!value && value != '0') return 'rating-unknown'

      value = parseFloat(value)
      if (value <= 4)  return 'rating0-4'
      if (value >= 97) return 'rating97-100'

      return `rating${this.mod4(value) + 1}-${this.mod4(value) + 4}`
    },

    toHex(value10) {
      return this.colorHash[this.toCss100(parseFloat(value10 * 10))]
    },
  },
  colorInput: {
    position: {
      firstColor:  '-14px',
      secondColor: '136px',
      thirdColor:  '194px',
      fourthColor: '256px',
      fifthColor:  '314px',
      lastColor:   '368px'
    }
  },
  calcTrend (curr, prev) {
    if ((curr || curr == 0) && prev && prev != 0) return this.round(((curr - prev) / prev) * 100);
    return null
  },

  timePeriods: {
    previous (sd, ed, trendRange) {
      if (trendRange == 'yoy') {
        return {
          start_date: dayjs(sd).add(-1, 'year').format("YYYY-MM-DD"),
          end_date:   dayjs(ed).add(-1, 'year').format("YYYY-MM-DD")
        }
      } else {
        const diff = dayjs(sd).diff(ed, 'day') - 1
        return {
          start_date: dayjs(sd).add(diff, 'day').format("YYYY-MM-DD"),
          end_date:   dayjs(sd).add(-1, 'day').format("YYYY-MM-DD"),
        }
      }
    }
  },

  kpis: {
    redirect(settings, data) {
      if (!settings.redirect) return;
      const { period, ratingType } = settings;
      const kpi = settings.segmentOption;

      let query = {
        rt: ratingType,
        sd: dayjs().add(-1, period).startOf(period).format("YYYY-MM-DD"),
        ed: dayjs().add(-1, period).endOf(period).format("YYYY-MM-DD")
      };

      if (["sanitary_safety", "health_precautions", "sustainable_travel"].includes(kpi)) query["rt"] = "sentiment";
      if (["accommodation", "attraction", "restaurant"].includes(kpi)) query["pt"] = kpi;

      if (kpi == "top_nationalities") query["rcn"] = data.map(d => d.title);
      if (kpi == "top_compositions")  query["tc"]  = data.map(d => d.title);
      if (kpi == "top_regions")       query["rg"]  = data.map(d => d.title);
      if (kpi == "top_cities")        query["ct"]  = data.map(d => d.title);

      const authParams = window.oly.utils.slice(this.$route.query, window.oly.utils.authParams);
      this.$router.push({ name: "destination_ratings_data", query: { ...authParams, ...query } });
    },
    saveData(data, params) {
      let { ratingType, context } = params;
      const kpi = params.segmentOption;
      let res = {
        current:  { [ratingType]: { review_count: "-", trend: "-", value: "-" } },
        previous: { [ratingType]: { review_count: "-", trend: "-", value: "-" } }
      };

      if (!data[kpi]) return res;

      if (context == "dashboard") {
        res = data[kpi];
        if (["sustainable_travel", "sanitary_safety", "health_precautions"].includes(kpi)) {
          res.current["numerical"]  = res.current.sentiment;
          res.previous["numerical"] = res.previous.sentiment;
        }

        if (!Array.isArray(res)) res = this.calculateTES(res);
        else res.forEach(top => top = this.calculateTES(top));
      } else {
        let current  = data[kpi].current.value  || 0;
        let previous = data[kpi].previous.value || 0;
        let trend    = null;
        if ((current || current == 0) && previous) trend = window.oly.utils.round(((current - previous) / previous) * 100);

        res.current[ratingType]  = {
          review_count: data[kpi].current.review_count,
          value: window.oly.utils.round(current > 10 ? current / 10 : current),
          trend: trend
        };
        res.previous[ratingType] = {
          review_count: data[kpi].previous.review_count,
          value: window.oly.utils.round(previous > 10 ? previous / 10 : previous)
        };
      }
      return res;
    },
    calculateTES(data) {
      ["current", "previous"].forEach(e => {
        if (!data[e].numerical || !data[e].sentiment) return;

        data[e]["tes"] = {
          value: window.oly.utils.calcTES(data[e].numerical.value, data[e].numerical.review_count, data[e].sentiment.value, data[e].sentiment.review_count),
          review_count: Math.max(data[e].numerical.review_count, data[e].sentiment.review_count)
        }
      });

      // Calculate trends...
      ["numerical", "sentiment", "tes"].forEach(type => {
        if (!data.current[type] || !data.previous[type]) return
        data["current"][type]["trend"] = window.oly.utils.round(((data.current[type].value - data.previous[type].value) / data.previous[type].value) * 100);
      });

      return data;
    },
  },

  charts: {
    loadApexGrids() {
      const gridBorders = document.querySelectorAll(".apexcharts-grid-borders");
      const grids       = document.querySelectorAll(".apexcharts-gridlines-horizontal");

      gridBorders.forEach((gb, i) => {
        const line = gb.firstChild;
        grids[i].prepend(line);
      });
    }
  },

  permissions: {
    can(route, context, contract, user, sidebar) {
      const permissions    = user.permissions_by_contract?.[contract.id] || [];
      const permittedPages = permissions.map(p => `${p.component}_${p.permission_type}`);

      // check if the entry needs to a certain context to be shown
      if (route.meta && route.meta.required_context) {
        let requiredContexts = route.meta.required_context;
        for (let i = 0; i < requiredContexts.length; i++) {
          if (!contract.products.includes(requiredContexts[i])) return false;
        }
      }

      if (route.meta && route.meta.hidden && sidebar) return false;
      if (route.meta && route.meta.always) return true;
      if (user.admin && permissions.length == 0) return true;

      if (route.name == "white_label" && (user.admin || user.api_permissions?.includes('whitelabel')) && this.hasWLContract)
        return true;
      if (route.name == "queries_editor" && user.admin) return true;

      // Check if a required permission is missing
      if (route.meta && route.meta.required_permissions) {
        let permissions = [...route.meta.required_permissions];
        if (permissions.includes('manager') && !user.manager) return false;
        if (permissions.includes('admin')) return user.admin;
        permissions = permissions.filter(p => !/manager|admin/.test(p));
        for(let i = 0; i < permissions.length; i++) {
          if (!permittedPages.includes(permissions[i])) return false;
        }
      }

      // Allow if an extra permission is present
      if (route.meta && route.meta.extra_permissions) {
        let permissions = [...route.meta.extra_permissions];
        for(let i = 0; i < permissions.length; i++) {
          if (permittedPages.includes(permissions[i])) return true;
        }
      }

      // If has children, show if any of them is allowed
      if (route.children?.length) return route.children.some(c => this.can(c, context, contract, user));
      return permissions.find(c => c.component == context && (c.permission_type == "index" || route.name == `${context}_${c.permission_type}`));
    },
  },

  map: {
    setMapLanguage(map, lang) {
      if (!map) return;
      const langCode = lang || this.$i18n.locale;
      ["label_country", "label_place_city", "label_airport", "label_road", "label_place_other", "label_country_other"].forEach(field => {
        map.setLayoutProperty(field, "text-field", ["get", `name:${langCode}`]);
      });
    }
  }

}
export default window.oly.utils;
